import React from 'react'
import ContactUs from '../../Common/ContactUs/ContactUs'
import FlexContainer from '../../Common/Components/FlexContainer'
import Xtwim from '../../assets/xTwin.webp';
const Xtwin = () => {
  return (
    <>
      <div className="container" >
        <FlexContainer
          src={Xtwim}
          tag={"Platform"}
          glow={"xTwin"}
          desc={
            <>
              <b>xTwin Digital Twin Solution</b> creates real-time <b>virtual replicas</b> of physical assets to optimize performance, 
              predict maintenance, and enhance decision-making. It integrates IoT and AI for predictive maintenance, simulation, 
              and data-driven insights. Key applications include <b>manufacturing, healthcare, automotive,</b> and <b>smart cities,</b> driving 
              efficiency and smarter operations.
            </>
          }
        />
      </div>
      <ContactUs />
    </>
  )
}

export default Xtwin