import React from "react";
import FlexContainer from "../../../Common/Components/FlexContainer";
import aboutUs from "../../../assets/about-us.webp";

const About = () => {
  return (
    <div className="container about">
      <FlexContainer
        src={aboutUs}
        tag={"Corporate Profile"}
        title={"SISDEA - A\nConfluence of "}
        glow={"AI and IoT Innovation"}
        desc={
          <>
            Sisdea is a global leader in <b>digital engineering</b> and <b>AI solutions</b>, dedicated to transforming industries through innovation, efficiency, and sustainability. We provide tailored <b>IoT</b>, <b>AI</b>, and <b>data analytics </b>solutions to enhance operations, improve safety, and drive growth across sectors like <b>automotive</b>, <b>manufacturing</b>, <b>logistics</b>, <b>construction</b>, and more.
          </>
        }
      />
    </div>
  );
};

export default About;
 