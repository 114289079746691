import React from 'react'
import FlexContainer from '../../../Common/Components/FlexContainer'
import JoinUsImg from '../../../assets/JoinUs.webp';
const JoinUs = () => {
  return (
    <div className="container">
    <FlexContainer
      src={JoinUsImg}
      tag={"Join Sisdea"}
      title={"Join Sisdea – Build the Future of AIoT"}
      desc={
        <>
        At  <b>Sisdea,</b> we’re on a mission to revolutionize industries through  <b>AIoT solutions</b> that drive innovation, efficiency, and sustainability. We’re looking for passionate individuals who are ready to break barriers and create <b>world-class AIoT solutions</b> for our <b>global customers.</b>
        <br/>If you’re an aspiring innovator in the IoT and AI world, eager to challenge the status quo and make an impact, <b>Sisdea</b> is the place for you.
        </>
      }
    />
  </div>
  )
}

export default JoinUs