import React from "react";
import TextCapsules from "../../../Common/Components/TextCapsules";
import CEO from "../../../assets/CEO.webp";
const Workforce = () => {
    return (
        <div className="container Workforce">
            <div className="Workforce-section1">
                <TextCapsules text={"Our Team"} />
                <div className="heading1">
                    Meet the{" "}
                    <span className="text-gradient">workforce</span>
                    <div className="paragraph1">
                        <b>Sisdea</b> is an AIoT solutions company founded by industry veterans with a proven track record of delivering innovative products for leading multinational corporations and startups.
                    </div>
                </div>
            </div>
            <div className="Workforce-section2">

                <img src={CEO} alt="CEO_Spanidea" />
                <div className="Workforce-content">
                <div className="Workforce-heading1">
                    Bhagirath Chaudhary,{" "}
                    <span className="text-gradient">CEO</span>
                    </div>
                        <div className="paragraph1">
                        <b>Bhagirath Choudhary</b> is a visionary entrepreneur with over 20 years of experience across the USA, Europe, and APAC. Known for his expertise in <b>IoT, wireless,</b> and <b>AI/ML,</b> he has successfully led startups to industry-changing exits and built high-growth companies.
                        </div>
                        <div className="paragraph1">
                        Before founding <b>Sisdea,</b> Bhagirath founded <b>Spanidea Systems,</b> growing it into a global leader with over 1,000 employees and more than 100 Fortune 500 clients. He also played a key role in the success of <b>Beceem</b> Communications (acquired by Broadcom) and <b>Atheros Communications</b> (now part of Qualcomm).
                        </div>
                        <div className="paragraph1">
                        A graduate of <b>IIT Madras,</b> Bhagirath drives Sisdea’s growth and innovation, overseeing <b>marketing, business development,</b> and <b>partnerships.</b> His relentless pursuit of innovation continues to propel Sisdea to new heights, setting the standard for the future of <b>AIoT</b>.
                    </div>
                    </div>
            </div>
        </div>
    )
}

export default Workforce;