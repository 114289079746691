import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import Consumer1 from '../../assets/Consumer1.webp';
import Consumer2 from '../../assets/Consumer2.webp';
import Consumer3 from '../../assets/Consumer3.webp';
import ConsumerImg from '../../assets/ConsumerImg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';

const Consumer = () => {
  const cardDetail = [
    {
      title: 'Personalized Experiences',
      description: 'AI-driven recommendations and customer insights.',
    },
    {
      title: 'Smart Products',
      description: 'Connected devices and real-time product monitoring.',
    },
    {
      title: 'Supply Chain Optimization',
      description: 'Real-time inventory tracking, demand forecasting, and logistics.',
    },
    {
      title: 'Customer Support',
      description: 'AI chatbots and feedback analysis for better engagement.',
    },
    {
      title: 'Sustainability',
      description: 'Eco-friendly products and waste reduction.',
    },
];



const cardData = [
    {
        img: Consumer1,
        title: 'Personalized services and enhanced user experience.',
    },
    {
        img: Consumer2,
        title: 'Optimized inventory and supply chains.',
    },
    {
        img: Consumer3,
        title: 'Cost savings and sustainability.',
    },
 
];
  return (
    <>
      <div className="container" >
                 <FlexContainer
                     src={ConsumerImg}
                     tag={"Industry"}
                     glow={"Consumer"}
                     desc={
                         <>
                            Sisdea uses <b>IoT, AI,</b> and <b>data analytics</b> to enhance <b>efficiency, customer satisfaction, </b> and <b>sustainability </b>in the consumer industry.

                         </>
                     }
                 />
             </div>
             <Cards
                 tag="Key Solutions"
                 title="Experience the Key Solutions with"
                 glow=" Consumer"
                 cardDetails={cardDetail}
             />
             <CardsV2
                 glow="Benefits"
                 cardData={cardData}
             />
    <Bannerv2 desc={"Sisdea helps the consumer industry improve operations, enhance customer experiences, and promote sustainability."}/>
    <ContactUs/>
    </>
   )
}

export default Consumer
