import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import utility1 from '../../assets/utility1.webp';
import utility2 from '../../assets/utility2.webp';
import utility3 from '../../assets/utility3.webp';
import utility4 from '../../assets/utility4.webp';
import Utitlityimg from '../../assets/UtilityImg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';

const Utitlity = () => {
  const cardDetail = [
    {
      title: 'Smart Grid Management',
      description: (<ul>
        <li><b>Real-Time Monitoring:</b> Tracks grid performance and detects outages instantly.</li>
        <li><b>Load Balancing:</b> AI-driven demand forecasting to optimize energy distribution.</li>
        <li><b>Energy Storage Integration:</b> Efficiently manages renewable energy sources like solar and wind.</li>
        <li><b>Fault Detection:</b> Identifies and resolves issues remotely.</li>
      </ul>)
    },
    {
      title: 'Predictive Maintenance',
      description: (<ul>
        <li><b>IoT Sensors:</b> Monitors critical infrastructure like transformers, pipelines, and substations.</li>
        <li><b>AI-Based Analytics:</b> Predicts equipment failures, reducing downtime and repair costs.</li>
        <li><b>Lifecycle Optimization:</b> Prolongs asset life with data-driven insights.</li>
      </ul>)
    },
    {
      title: 'Smart Metering',
      description: (<ul>
        <li><b>Automated Meter Reading (AMR):</b> Real-time consumption tracking for utilities and customers.</li>
        <li><b>Dynamic Pricing:</b> Implements time-of-use pricing to balance demand and reduce costs.</li>
        <li><b>Billing Accuracy:</b> Enhances transparency with precise data collection.</li>
      </ul>)
    },
    {
      title: 'Renewable Energy Integration',
      description: (<ul>
        <li><b>Grid Synchronization:</b> Seamlessly integrates solar, wind, and other renewables into existing grids.</li>
        <li><b>Microgrid Management:</b> Optimizes small-scale grids for remote or rural areas.</li>
      </ul>)
    },
    {
      title: 'Connected Infrastructure',
      description: (<ul>
        <li><b>Pipeline Monitoring:</b> Tracks pressure and flow to detect leaks or inefficiencies.</li>
        <li><b>Water Utility Management:</b> Monitors quality, flow, and consumption in real time.</li>
        <li><b>Smart Lighting:</b> Optimizes energy usage and maintenance schedules.</li>
      </ul>)
    }
  ];



  const cardData = [
    {
      img: utility1,
      title: 'Enhanced Efficiency',
      description: 'Reduces energy waste and operational costs.'
    },
    {
      img: utility2,
      title: 'Improved Reliability',
      description: 'Minimizes downtime and ensures continuous service.'
    },
    {
      img: utility3,
      title: 'Sustainability',
      description: 'Enables eco-friendly operations with renewable energy solutions.'
    },
    {
      img: utility4,
      title: 'Customer Satisfaction',
      description: 'Offers accurate billing and enhanced transparency.'
    },

  ];
  return (
    <>
      <div className="container" >
        <FlexContainer
          src={Utitlityimg}
          tag={"Industry"}
          glow={"Utility"}
          desc={
            <>
              Sisdea leverages IoT, AI, and advanced analytics to revolutionize the utility sector, focusing on <b>energy management, smart grids, predictive maintenance, </b>and <b>asset monitoring</b> to <b>enhance efficiency, reliability, </b>and <b>sustainability.</b>

            </>
          }
        />
      </div>
      <Cards
        tag="Key Solutions"
        title="Experience the Key Solutions with"
        glow=" Utility"
        cardDetails={cardDetail}
      />
      <CardsV2
        glow="Benefits"
        cardData={cardData}
      />
      <Bannerv2 desc={"Sisdea’s solutions empower utility providers to modernize operations, improve service delivery, and achieve sustainability goals through innovative technology."} />
      <ContactUs />
    </>
  )
}

export default Utitlity
