import React from 'react'
import Cards from '../../Common/Components/Cards';
import FlexContainer from '../../Common/Components/FlexContainer';
import ThingsImg from '../../assets/ThingsImg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';
import Things1 from '../../assets/Things1.webp';
import Things2 from '../../assets/Things2.webp';
import Things3 from '../../assets/Things3.webp';
import Things4 from '../../assets/Things4.webp';
import Things5 from '../../assets/Things5.webp';
import CardsV2 from '../../Common/Components/CardsV2';
import Bannerv2 from '../../Common/Components/Bannerv2';
import TextCapsules from '../../Common/Components/TextCapsules';
import Things_benefits from '../../assets/Things-benefits.webp';
import './Solution.css'
const Things = () => {



    const cardDetail = [
        {
            title: 'Seamless Connectivity',
            description: (
                <ul style={{ padding: "20px" }}>
                    <li>Connects devices such as smart locks, handheld gadgets, sensors, cameras, and more into a single intelligent network.</li>
                    <li>Supports multiple communication protocols (Wi-Fi, Bluetooth, Zigbee, NB-IoT, etc.) for flexible integration.</li>
                </ul>
            ),
        },
        {
            title: 'Smart Locks',
            description: (
                <ul style={{ padding: "20px" }}>
                    <li>Offer secure and convenient access management for residential, commercial, and industrial settings.</li>
                    <li>Features include remote locking/unlocking, real-time access tracking, and multi-user permissions.</li>
                </ul>
            ),
        },
        {
            title: 'Handheld Gadgets',
            description: (
                <ul style={{ padding: "20px" }}>
                    <li>Tools like barcode scanners, portable monitors, and communication devices improve operational workflows and on-the-go productivity.</li>
                    <li>Equipped with IoT sensors for seamless data exchange.
                    </li>
                </ul>
            ),
        },
        {
            title: 'AI-Driven Insights',
            description: "Advanced AI algorithms analyze usage data, detect anomalies, and recommend optimizations for energy savings, safety, and efficiency.",
        },
        {
            title: 'Interoperability',
            description: (
                <ul style={{ padding: "20px" }}>
                    <li>Integrates with third-party platforms, smart hubs, and enterprise systems for holistic control and management.</li>
                    <li>Enables interoperability across devices like cameras, HVAC systems, and industrial machinery.</li>
                </ul>
            ),
        },
        {
            title: 'Real-Time Monitoring and Alerts',
            description: "Offers 24/7 monitoring of connected devices with instant notifications for security breaches, equipment failures, or abnormal activities.",
        },
        {
            title: 'Cloud and Edge Integration',
            description: "Combines edge computing for low-latency operations and cloud platforms for centralized data storage and analytics.",
        },
        {
            title: 'Enhanced Security',
            description: "Implements advanced encryption and authentication protocols to secure connected devices against cyber threats.",
        }

    ];



    const cardData = [
        {
            img: Things1,
            title: 'Construction',
            description: 'Ensures worker safety and monitors progress while minimizing risks and inefficiencies.'
        },
        {
            img: Things2,
            title: 'Industrial Facilities',
            description: 'Provides real-time monitoring of machinery and personnel to maintain compliance and security.'
        },
        {
            img: Things3,
            title: 'Smart Factories',
            description: 'Enhances automation and safety in production facilities through advanced visibility.'
        },
        {
            img: Things4,
            title: 'Logistics and Warehousing',
            description: 'Tracks vehicle movements, monitors loading/unloading zones, and ensures Things safety.'
        },
        {
            img: Things5,
            title: 'Logistics and Warehousing',
            description: 'Tracks vehicle movements, monitors loading/unloading zones, and ensures Things safety.'
        }

    ];



    return (

        <>
            <div className="container" >
                <FlexContainer
                    src={ThingsImg}
                    tag={"Solution"}
                    glow={"CONNECTED THINGS"}
                    desc={
                        <>
                            <b>Connected Things Solution</b> is an advanced IoT ecosystem that connects diverse smart devices to enhance <b>security, automation, productivity, </b>and <b>user experience </b>across various domains. By integrating AI and IoT technologies, this solution creates a unified platform for managing connected assets, streamlining operations, and unlocking new possibilities.
                        </>
                    }
                />
            </div>
            <Cards
                tag="Key Features"
                title="Experience the Key Features with"
                glow=" Connected Things Solution"
                cardDetails={cardDetail}
            />
            <CardsV2
                glow="Applications"
                cardData={cardData}
            />
         
            <div className="container benefit" >
                <div className='benefits-section-1'>
                    <TextCapsules text={"Benefits"} />
                    <div className="heading1">
                        Benefits with{" "}
                        <span className="text-gradient"> CONNECTED THINGS Solution</span>
                    </div>
                </div>
                <div className='benefits-section-2'>
                    <img src={Things_benefits} alt="" />
                    <div className='section-2-2' >
                        <div className='box'>

                            <div className='box-in'>
                                <div className='dot'></div>
                                <div className='headingv3'>Improved Operational Efficiency</div>

                            </div>
                            <div className='text'> Automates routine tasks and optimizes workflows.</div>

                        </div>

                        <div className='box'>

                            <div className='box-in'>
                                <div className='dot'></div>
                                <div className='headingv3'>Enhanced Safety and Security</div>

                            </div>
                            <div className='text'>Monitors and protects assets, facilities, and people.  </div>

                        </div>
                        <div className='box'>

                            <div className='box-in'>
                                <div className='dot'></div>
                                <div className='headingv3'>Cost Savings</div>

                            </div>
                            <div className='text'>  Reduces energy consumption, downtime, and manual intervention costs.</div>

                        </div>
                        <div className='box'>

                            <div className='box-in'>
                                <div className='dot'></div>
                                <div className='headingv3'>Scalability</div>

                            </div>
                            <div className='text'> Adapts to growing needs with flexible and modular components.
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Bannerv2
                desc="The Sisdea Connected Things Solution represents a transformative approach to leveraging IoT and AI for smart, secure, and efficient environments, driving innovation across industries and personal spaces."
            />
            <ContactUs />
        </>
    )
}

export default Things
