import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import Manufacturing1 from '../../assets/Manufacturing1.webp';
import Manufacturing2 from '../../assets/Manufacturing2.webp';
import Manufacturing3 from '../../assets/Manufacturing3.webp';
import ManufacturingImg from '../../assets/Manufacturingimg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';
const Manufacturing = () => {
  const cardDetail = [
    {
      title: 'Smart Factory Automation',
      description: 'IoT-enabled machines for real-time monitoring, energy optimization, and automation.',
    },
    {
      title: 'Predictive Maintenance',
      description: 'IoT sensors and AI analytics predict equipment failures and reduce downtime.',
    },
    {
      title: 'Quality Assurance',
      description: 'AI vision systems and data analytics ensure high-quality standards.',
    },
    {
      title: 'Supply Chain Optimization',
      description: 'Real-time tracking, demand forecasting, and automated reordering.',
    },
    {
      title: 'Worker Safety',
      description: 'Wearables and AI video analytics to monitor health, safety, and compliance.',
    },
    {
      title: 'Sustainability',
      description: 'Waste management, emission tracking, and circular manufacturing.',
    },
  ];



const cardData = [
    {
        img: Manufacturing1,
        title: 'Improved efficiency and reduced operational costs.',
    },
    {
        img: Manufacturing2,
        title: 'Enhanced product quality and worker safety.',
    },
    {
        img: Manufacturing3,
        title: 'Sustainable and eco-friendly practices.',
    },
 
];
  return (
    <>
      <div className="container" >
                 <FlexContainer
                     src={ManufacturingImg}
                     tag={"Industry"}
                     glow={"Manufacturing"}
                     desc={
                         <>
                          Sisdea leverages IoT, AI, and digital twin technologies to <b>enhance efficiency, productivity,</b> and <b>sustainability</b> in manufacturing operations.

                         </>
                     }
                 />
             </div>
             <Cards
                 tag="Key Solutions"
                 title="Experience the Key Solutions with"
                 glow=" Manufacturing"
                 cardDetails={cardDetail}
             />
             <CardsV2
                 glow="Benefits"
                 cardData={cardData}
             />
    <Bannerv2 desc={"Sisdea’s solutions help manufacturers optimize operations, reduce costs, and improve safety and quality."}/>
    <ContactUs/>
    </>
   )
}

export default Manufacturing
