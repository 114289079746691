import React from 'react'
import './Components.css'
const Bannerv2 = ({desc}) => {
  return (
    <div className="bannerv2-head"> 
    <div className='bannerv2'>
    <div className='bannerv2-text'>  
    {desc}
    </div>  
    </div>
    </div>
  )
}

export default Bannerv2
