import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import Construction1 from '../../assets/Construction1.webp';
import Construction2 from '../../assets/Construction2.webp';
import Construction3 from '../../assets/Construction3.webp';
import ConstructionImg from '../../assets/constructionimg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';

const Construction = () => {
  const cardDetail = [
    {
      title: 'Project Management',
      description: 'Real-time tracking, BIM integration, and automated reporting.',
    },
    {
      title: 'Asset Tracking',
      description: 'Real-time equipment and fleet monitoring, maintenance tracking.',
    },
    {
      title: 'Worker Safety',
      description: 'Wearable devices, AI surveillance, and environmental monitoring.',
    },
    {
      title: 'Site Monitoring',
      description: 'AI-based surveillance, drones, and site analytics.',
    },
    {
      title: 'Sustainability',
      description: 'Energy monitoring, waste tracking, and eco-friendly practices.',
    },
];



const cardData = [
    {
        img: Construction1,
        title: 'Improved efficiency and safety.',
    },
    {
        img: Construction2,
        title: 'Reduced costs and waste.',
    },
    {
        img: Construction3,
        title: 'Enhanced project delivery and sustainability.',
    },
 
];
  return (
    <>
      <div className="container" >
                 <FlexContainer
                     src={ConstructionImg}
                     tag={"Industry"}
                     glow={"Construction"}
                     desc={
                         <>
                           Sisdea leverages IoT, AI, and data analytics to enhance <b>efficiency, safety,</b> and <b>sustainability </b>in the Oil & Gas industry.
                         </>
                     }
                 />
             </div>
             <Cards
                 tag="Key Solutions"
                 title="Experience the Key Solutions with"
                 glow=" Construction"
                 cardDetails={cardDetail}
             />
             <CardsV2
                 glow="Benefits"
                 cardData={cardData}
             />
    <Bannerv2 desc={"Sisdea helps the construction industry optimize operations, improve safety, and reduce costs through advanced technology."}/>
    <ContactUs/>
    </>
   )
}

export default Construction
