import React from 'react';
import ContactUs from '../../Common/ContactUs/ContactUs';
import OurLocation from './Section/OurLocation';
const Contact = () => {
  return (
    <>
    <ContactUs/>
    <OurLocation/>
    </>
  )
}

export default Contact;