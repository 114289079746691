import React from 'react';
import TextCapsules from '../../../Common/Components/TextCapsules';
import USA from "../../../assets/USA.webp";
import Jodhpur from "../../../assets/Jodhpur.webp";
import Banglore from "../../../assets/Banglore.webp";
import './OurLocation.css'
const OurLocation = () => {
  return (
    <>
      <div className="our-location container">
        <div className='our-location-section1'>
          <TextCapsules text={"Locations"} />
          <div className="heading1">
            Our{" "}
            <span className="text-gradient">Locations</span>
          </div>
        </div>
        <div className='Location-col'>



          <div className='Location-sec'>
            <img src={USA} />
            <div className="heading2">USA</div>
            <div className='Location-details'>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                  <path d="M6.0013 17.3333C5.80686 17.3333 5.64019 17.2777 5.5013 17.1666C5.36241 17.0555 5.25825 16.9097 5.1888 16.7291C4.92491 15.9513 4.59158 15.2222 4.1888 14.5416C3.79991 13.8611 3.2513 13.0625 2.54297 12.1458C1.83464 11.2291 1.25825 10.3541 0.813802 9.52079C0.383247 8.68746 0.167969 7.68051 0.167969 6.49996C0.167969 4.87496 0.730469 3.49996 1.85547 2.37496C2.99436 1.23607 4.3763 0.666626 6.0013 0.666626C7.6263 0.666626 9.0013 1.23607 10.1263 2.37496C11.2652 3.49996 11.8346 4.87496 11.8346 6.49996C11.8346 7.76385 11.5916 8.8194 11.1055 9.66663C10.6332 10.5 10.0846 11.3263 9.45964 12.1458C8.70964 13.1458 8.14019 13.9791 7.7513 14.6458C7.3763 15.2986 7.0638 15.993 6.8138 16.7291C6.74436 16.9236 6.63325 17.0763 6.48047 17.1875C6.34158 17.2847 6.18186 17.3333 6.0013 17.3333ZM6.0013 14.3541C6.23741 13.8819 6.5013 13.4166 6.79297 12.9583C7.09852 12.5 7.54297 11.8888 8.1263 11.125C8.72353 10.3472 9.20963 9.6319 9.58463 8.97912C9.97352 8.31246 10.168 7.48607 10.168 6.49996C10.168 5.34718 9.75825 4.36801 8.9388 3.56246C8.13325 2.74301 7.15408 2.33329 6.0013 2.33329C4.84852 2.33329 3.86241 2.74301 3.04297 3.56246C2.23741 4.36801 1.83464 5.34718 1.83464 6.49996C1.83464 7.48607 2.02214 8.31246 2.39714 8.97912C2.78602 9.6319 3.27908 10.3472 3.8763 11.125C4.45964 11.8888 4.89714 12.5 5.1888 12.9583C5.49436 13.4166 5.76519 13.8819 6.0013 14.3541ZM6.0013 8.58329C6.58464 8.58329 7.07769 8.3819 7.48047 7.97913C7.88325 7.57635 8.08464 7.08329 8.08464 6.49996C8.08464 5.91663 7.88325 5.42357 7.48047 5.02079C7.07769 4.61801 6.58464 4.41663 6.0013 4.41663C5.41797 4.41663 4.92491 4.61801 4.52214 5.02079C4.11936 5.42357 3.91797 5.91663 3.91797 6.49996C3.91797 7.08329 4.11936 7.57635 4.52214 7.97913C4.92491 8.3819 5.41797 8.58329 6.0013 8.58329Z" fill="#0B0B0B" />
                </svg>
                <div className="paragraph1">
                  Sisdea Inc, 5201 Great America Pkwy Santa Clara 95054
                </div>
              </div>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M12.5435 4.66671C13.3574 4.82551 14.1055 5.22359 14.6919 5.80999C15.2783 6.39639 15.6763 7.14443 15.8351 7.95837M12.5435 1.33337C14.2345 1.52124 15.8115 2.27852 17.0153 3.48088C18.2192 4.68324 18.9785 6.25922 19.1685 7.95004M18.3351 14.6V17.1C18.3361 17.3321 18.2886 17.5618 18.1956 17.7745C18.1026 17.9871 17.9662 18.178 17.7952 18.3349C17.6242 18.4918 17.4223 18.6113 17.2024 18.6856C16.9826 18.76 16.7496 18.7876 16.5185 18.7667C13.9542 18.4881 11.491 17.6118 9.32682 16.2084C7.31334 14.9289 5.60626 13.2219 4.32682 11.2084C2.91846 9.03438 2.04202 6.5592 1.76848 3.98337C1.74766 3.75293 1.77505 3.52067 1.8489 3.30139C1.92275 3.08211 2.04146 2.88061 2.19745 2.70972C2.35345 2.53883 2.54332 2.4023 2.75498 2.30881C2.96663 2.21532 3.19543 2.16693 3.42682 2.16671H5.92682C6.33124 2.16273 6.72331 2.30594 7.02995 2.56965C7.33659 2.83336 7.53688 3.19958 7.59348 3.60004C7.699 4.4001 7.89469 5.18565 8.17682 5.94171C8.28894 6.23998 8.3132 6.56414 8.24674 6.87577C8.18027 7.18741 8.02587 7.47347 7.80182 7.70004L6.74348 8.75837C7.92978 10.8447 9.65719 12.5721 11.7435 13.7584L12.8018 12.7C13.0284 12.476 13.3144 12.3216 13.6261 12.2551C13.9377 12.1887 14.2619 12.2129 14.5601 12.325C15.3162 12.6072 16.1018 12.8029 16.9018 12.9084C17.3066 12.9655 17.6763 13.1694 17.9406 13.4813C18.2049 13.7932 18.3453 14.1914 18.3351 14.6Z" stroke="#0B0B0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="paragraph1">
                  +1 669 226 7863
                </div>
              </div>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path d="M2.33464 14.1666C1.8763 14.1666 1.48394 14.0034 1.15755 13.677C0.831163 13.3506 0.667969 12.9583 0.667969 12.4999V2.49992C0.667969 2.04159 0.831163 1.64922 1.15755 1.32284C1.48394 0.996446 1.8763 0.833252 2.33464 0.833252H15.668C16.1263 0.833252 16.5187 0.996446 16.8451 1.32284C17.1714 1.64922 17.3346 2.04159 17.3346 2.49992V12.4999C17.3346 12.9583 17.1714 13.3506 16.8451 13.677C16.5187 14.0034 16.1263 14.1666 15.668 14.1666H2.33464ZM9.0013 8.33325L2.33464 4.16659V12.4999H15.668V4.16659L9.0013 8.33325ZM9.0013 6.66658L15.668 2.49992H2.33464L9.0013 6.66658ZM2.33464 4.16659V2.49992V12.4999V4.16659Z" fill="#0B0B0B" />
                </svg>
                <div className="paragraph1">
                  hello@sisdea.ai
                </div>
              </div>
            </div>
          </div>



          <div className='Location-sec'>
            <img src={Banglore} />
            <div className="heading2">India - Bangalore</div>
            <div className='Location-details'>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 18" fill="none">
                  <path d="M6.0013 17.3333C5.80686 17.3333 5.64019 17.2777 5.5013 17.1666C5.36241 17.0555 5.25825 16.9097 5.1888 16.7291C4.92491 15.9513 4.59158 15.2222 4.1888 14.5416C3.79991 13.8611 3.2513 13.0625 2.54297 12.1458C1.83464 11.2291 1.25825 10.3541 0.813802 9.52079C0.383247 8.68746 0.167969 7.68051 0.167969 6.49996C0.167969 4.87496 0.730469 3.49996 1.85547 2.37496C2.99436 1.23607 4.3763 0.666626 6.0013 0.666626C7.6263 0.666626 9.0013 1.23607 10.1263 2.37496C11.2652 3.49996 11.8346 4.87496 11.8346 6.49996C11.8346 7.76385 11.5916 8.8194 11.1055 9.66663C10.6332 10.5 10.0846 11.3263 9.45964 12.1458C8.70964 13.1458 8.14019 13.9791 7.7513 14.6458C7.3763 15.2986 7.0638 15.993 6.8138 16.7291C6.74436 16.9236 6.63325 17.0763 6.48047 17.1875C6.34158 17.2847 6.18186 17.3333 6.0013 17.3333ZM6.0013 14.3541C6.23741 13.8819 6.5013 13.4166 6.79297 12.9583C7.09852 12.5 7.54297 11.8888 8.1263 11.125C8.72353 10.3472 9.20963 9.6319 9.58463 8.97912C9.97352 8.31246 10.168 7.48607 10.168 6.49996C10.168 5.34718 9.75825 4.36801 8.9388 3.56246C8.13325 2.74301 7.15408 2.33329 6.0013 2.33329C4.84852 2.33329 3.86241 2.74301 3.04297 3.56246C2.23741 4.36801 1.83464 5.34718 1.83464 6.49996C1.83464 7.48607 2.02214 8.31246 2.39714 8.97912C2.78602 9.6319 3.27908 10.3472 3.8763 11.125C4.45964 11.8888 4.89714 12.5 5.1888 12.9583C5.49436 13.4166 5.76519 13.8819 6.0013 14.3541ZM6.0013 8.58329C6.58464 8.58329 7.07769 8.3819 7.48047 7.97913C7.88325 7.57635 8.08464 7.08329 8.08464 6.49996C8.08464 5.91663 7.88325 5.42357 7.48047 5.02079C7.07769 4.61801 6.58464 4.41663 6.0013 4.41663C5.41797 4.41663 4.92491 4.61801 4.52214 5.02079C4.11936 5.42357 3.91797 5.91663 3.91797 6.49996C3.91797 7.08329 4.11936 7.57635 4.52214 7.97913C4.92491 8.3819 5.41797 8.58329 6.0013 8.58329Z" fill="#0B0B0B" />
                </svg>
                <div className="paragraph1">
                  Sisdea AI Pvt Ltd Sakti Statesman, 10th Floor, Green Glen Layout, Bellandur, Bengaluru, Karnataka 560103
                </div>
              </div>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M12.5435 4.66671C13.3574 4.82551 14.1055 5.22359 14.6919 5.80999C15.2783 6.39639 15.6763 7.14443 15.8351 7.95837M12.5435 1.33337C14.2345 1.52124 15.8115 2.27852 17.0153 3.48088C18.2192 4.68324 18.9785 6.25922 19.1685 7.95004M18.3351 14.6V17.1C18.3361 17.3321 18.2886 17.5618 18.1956 17.7745C18.1026 17.9871 17.9662 18.178 17.7952 18.3349C17.6242 18.4918 17.4223 18.6113 17.2024 18.6856C16.9826 18.76 16.7496 18.7876 16.5185 18.7667C13.9542 18.4881 11.491 17.6118 9.32682 16.2084C7.31334 14.9289 5.60626 13.2219 4.32682 11.2084C2.91846 9.03438 2.04202 6.5592 1.76848 3.98337C1.74766 3.75293 1.77505 3.52067 1.8489 3.30139C1.92275 3.08211 2.04146 2.88061 2.19745 2.70972C2.35345 2.53883 2.54332 2.4023 2.75498 2.30881C2.96663 2.21532 3.19543 2.16693 3.42682 2.16671H5.92682C6.33124 2.16273 6.72331 2.30594 7.02995 2.56965C7.33659 2.83336 7.53688 3.19958 7.59348 3.60004C7.699 4.4001 7.89469 5.18565 8.17682 5.94171C8.28894 6.23998 8.3132 6.56414 8.24674 6.87577C8.18027 7.18741 8.02587 7.47347 7.80182 7.70004L6.74348 8.75837C7.92978 10.8447 9.65719 12.5721 11.7435 13.7584L12.8018 12.7C13.0284 12.476 13.3144 12.3216 13.6261 12.2551C13.9377 12.1887 14.2619 12.2129 14.5601 12.325C15.3162 12.6072 16.1018 12.8029 16.9018 12.9084C17.3066 12.9655 17.6763 13.1694 17.9406 13.4813C18.2049 13.7932 18.3453 14.1914 18.3351 14.6Z" stroke="#0B0B0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="paragraph1">
                  +91 91169 40241
                </div>
              </div>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path d="M2.33464 14.1666C1.8763 14.1666 1.48394 14.0034 1.15755 13.677C0.831163 13.3506 0.667969 12.9583 0.667969 12.4999V2.49992C0.667969 2.04159 0.831163 1.64922 1.15755 1.32284C1.48394 0.996446 1.8763 0.833252 2.33464 0.833252H15.668C16.1263 0.833252 16.5187 0.996446 16.8451 1.32284C17.1714 1.64922 17.3346 2.04159 17.3346 2.49992V12.4999C17.3346 12.9583 17.1714 13.3506 16.8451 13.677C16.5187 14.0034 16.1263 14.1666 15.668 14.1666H2.33464ZM9.0013 8.33325L2.33464 4.16659V12.4999H15.668V4.16659L9.0013 8.33325ZM9.0013 6.66658L15.668 2.49992H2.33464L9.0013 6.66658ZM2.33464 4.16659V2.49992V12.4999V4.16659Z" fill="#0B0B0B" />
                </svg>
                <div className="paragraph1">
                  hello@sisdea.ai
                </div>
              </div>
            </div>
          </div>


          <div className='Location-sec'>
            <img src={Jodhpur} />
            <div className="heading2">India - Jodhpur</div>
            <div className='Location-details'>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18" fill="none">
                  <path d="M6.0013 17.3333C5.80686 17.3333 5.64019 17.2777 5.5013 17.1666C5.36241 17.0555 5.25825 16.9097 5.1888 16.7291C4.92491 15.9513 4.59158 15.2222 4.1888 14.5416C3.79991 13.8611 3.2513 13.0625 2.54297 12.1458C1.83464 11.2291 1.25825 10.3541 0.813802 9.52079C0.383247 8.68746 0.167969 7.68051 0.167969 6.49996C0.167969 4.87496 0.730469 3.49996 1.85547 2.37496C2.99436 1.23607 4.3763 0.666626 6.0013 0.666626C7.6263 0.666626 9.0013 1.23607 10.1263 2.37496C11.2652 3.49996 11.8346 4.87496 11.8346 6.49996C11.8346 7.76385 11.5916 8.8194 11.1055 9.66663C10.6332 10.5 10.0846 11.3263 9.45964 12.1458C8.70964 13.1458 8.14019 13.9791 7.7513 14.6458C7.3763 15.2986 7.0638 15.993 6.8138 16.7291C6.74436 16.9236 6.63325 17.0763 6.48047 17.1875C6.34158 17.2847 6.18186 17.3333 6.0013 17.3333ZM6.0013 14.3541C6.23741 13.8819 6.5013 13.4166 6.79297 12.9583C7.09852 12.5 7.54297 11.8888 8.1263 11.125C8.72353 10.3472 9.20963 9.6319 9.58463 8.97912C9.97352 8.31246 10.168 7.48607 10.168 6.49996C10.168 5.34718 9.75825 4.36801 8.9388 3.56246C8.13325 2.74301 7.15408 2.33329 6.0013 2.33329C4.84852 2.33329 3.86241 2.74301 3.04297 3.56246C2.23741 4.36801 1.83464 5.34718 1.83464 6.49996C1.83464 7.48607 2.02214 8.31246 2.39714 8.97912C2.78602 9.6319 3.27908 10.3472 3.8763 11.125C4.45964 11.8888 4.89714 12.5 5.1888 12.9583C5.49436 13.4166 5.76519 13.8819 6.0013 14.3541ZM6.0013 8.58329C6.58464 8.58329 7.07769 8.3819 7.48047 7.97913C7.88325 7.57635 8.08464 7.08329 8.08464 6.49996C8.08464 5.91663 7.88325 5.42357 7.48047 5.02079C7.07769 4.61801 6.58464 4.41663 6.0013 4.41663C5.41797 4.41663 4.92491 4.61801 4.52214 5.02079C4.11936 5.42357 3.91797 5.91663 3.91797 6.49996C3.91797 7.08329 4.11936 7.57635 4.52214 7.97913C4.92491 8.3819 5.41797 8.58329 6.0013 8.58329Z" fill="#0B0B0B" />
                </svg>
                <div className="paragraph1">
                  Sisdea AI Pvt Ltd  STPI Cyber-1, Near Saras dairy, Jodhpur Rajasthan - 342003
                </div>
              </div>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M12.5435 4.66671C13.3574 4.82551 14.1055 5.22359 14.6919 5.80999C15.2783 6.39639 15.6763 7.14443 15.8351 7.95837M12.5435 1.33337C14.2345 1.52124 15.8115 2.27852 17.0153 3.48088C18.2192 4.68324 18.9785 6.25922 19.1685 7.95004M18.3351 14.6V17.1C18.3361 17.3321 18.2886 17.5618 18.1956 17.7745C18.1026 17.9871 17.9662 18.178 17.7952 18.3349C17.6242 18.4918 17.4223 18.6113 17.2024 18.6856C16.9826 18.76 16.7496 18.7876 16.5185 18.7667C13.9542 18.4881 11.491 17.6118 9.32682 16.2084C7.31334 14.9289 5.60626 13.2219 4.32682 11.2084C2.91846 9.03438 2.04202 6.5592 1.76848 3.98337C1.74766 3.75293 1.77505 3.52067 1.8489 3.30139C1.92275 3.08211 2.04146 2.88061 2.19745 2.70972C2.35345 2.53883 2.54332 2.4023 2.75498 2.30881C2.96663 2.21532 3.19543 2.16693 3.42682 2.16671H5.92682C6.33124 2.16273 6.72331 2.30594 7.02995 2.56965C7.33659 2.83336 7.53688 3.19958 7.59348 3.60004C7.699 4.4001 7.89469 5.18565 8.17682 5.94171C8.28894 6.23998 8.3132 6.56414 8.24674 6.87577C8.18027 7.18741 8.02587 7.47347 7.80182 7.70004L6.74348 8.75837C7.92978 10.8447 9.65719 12.5721 11.7435 13.7584L12.8018 12.7C13.0284 12.476 13.3144 12.3216 13.6261 12.2551C13.9377 12.1887 14.2619 12.2129 14.5601 12.325C15.3162 12.6072 16.1018 12.8029 16.9018 12.9084C17.3066 12.9655 17.6763 13.1694 17.9406 13.4813C18.2049 13.7932 18.3453 14.1914 18.3351 14.6Z" stroke="#0B0B0B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <div className="paragraph1">
                  +91 91169 40241
                </div>
              </div>
              <div className='detail'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 18 15" fill="none">
                  <path d="M2.33464 14.1666C1.8763 14.1666 1.48394 14.0034 1.15755 13.677C0.831163 13.3506 0.667969 12.9583 0.667969 12.4999V2.49992C0.667969 2.04159 0.831163 1.64922 1.15755 1.32284C1.48394 0.996446 1.8763 0.833252 2.33464 0.833252H15.668C16.1263 0.833252 16.5187 0.996446 16.8451 1.32284C17.1714 1.64922 17.3346 2.04159 17.3346 2.49992V12.4999C17.3346 12.9583 17.1714 13.3506 16.8451 13.677C16.5187 14.0034 16.1263 14.1666 15.668 14.1666H2.33464ZM9.0013 8.33325L2.33464 4.16659V12.4999H15.668V4.16659L9.0013 8.33325ZM9.0013 6.66658L15.668 2.49992H2.33464L9.0013 6.66658ZM2.33464 4.16659V2.49992V12.4999V4.16659Z" fill="#0B0B0B" />
                </svg>
                <div className="paragraph1">
                  hello@sisdea.ai
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </>
  )
}

export default OurLocation;