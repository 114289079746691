import React from "react";
import TextCapsules from "../../../Common/Components/TextCapsules";
import whatWeOffer from "../../../assets/WhatWeOfferImg.webp";
import whatWeDoBrainIcon from "../../../assets/whatWeDoBrainIcon.webp";
import whatWeDoClickIcon from "../../../assets/whatWeDoClickIcon.webp";
import whatWeDoSettingIcon from "../../../assets/whatWeDoSetting.webp";
import './WhatWeOffer.css'

const WhatWeOffer = () => {
    return (
        <>
        <div className="container whatWeDo">
            <div className="whatWeDo-section1">
                <TextCapsules text={"Benefits"} />
                <div className="heading1">
                What We {" "}
                    <span className="text-gradient">Offer</span>
                </div>
            </div>
            <div className="whatWeDo-section2">
                <img src={whatWeOffer} />
                <div className="whatWeDo-content">
                    <div className="whatWeDo-Para">
                        <div className="whatWeDo-icon">
                            <img src={whatWeDoBrainIcon} alt="whatWeDoBrainIcon" />
                        </div>
                        <div className="whatWeDo-text">
                            <div className="heading2">Collaborative Environment</div>
                            <div className="paragraph1">
                            Work alongside industry veterans and cutting-edge technologists.

                            </div>
                        </div>
                    </div>
                    <div className="whatWeDo-Para">
                        <div className="whatWeDo-icon">
                            <img src={whatWeDoClickIcon} alt="whatWeDoBrainIcon" />
                        </div>
                        <div className="whatWeDo-text">
                            <div className="heading2">Career Growth</div>
                            <div className="paragraph1">
                               Unlock opportunities for personal and professional development.
                            </div></div>
                    </div>
                    <div className="whatWeDo-Para">
                        <div className="whatWeDo-icon">
                            <img src={whatWeDoSettingIcon} alt="whatWeDoBrainIcon" />
                        </div>
                        <div className="whatWeDo-text">
                            <div className="heading2">Global Impact</div>
                            <div className="paragraph1">
                            Build solutions that transform industries and shape the future.

                            </div></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="whatweoffer-join-us paragraph1"> Join us and be part of a team that’s driving the next wave of innovation in AIoT.<br/> Let’s create a smarter, more connected world.</div>
        </>
    )
}

export default WhatWeOffer