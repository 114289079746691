import React from "react";
import About from "./Sections/About";
import "./AboutUs.css";
import CoreValues from "./Sections/CoreValues";
import WhatWeDo from "./Sections/WhatWeDo";
import Workforce from "./Sections/Workforce";
const AboutUs = () => {
  return (
    <div>
      <About />
      <CoreValues />
      <WhatWeDo/>
      <Workforce/>
    </div>
  );
};

export default AboutUs;
