import React from "react";
import TextCapsules from "../../../Common/Components/TextCapsules";
import whatWeDo from "../../../assets/whatWeDoImg.webp";
import whatWeDoBrainIcon from "../../../assets/whatWeDoBrainIcon.webp";
import whatWeDoClickIcon from "../../../assets/whatWeDoClickIcon.webp";
import whatWeDoSettingIcon from "../../../assets/whatWeDoSetting.webp";
const WhatWeDo = () => {
    return (
        <div className="container whatWeDo">
            <div className="whatWeDo-section1">
                <TextCapsules text={"Work"} />
                <div className="heading1">
                    What we{" "}
                    <span className="text-gradient">Do</span>
                </div>
            </div>
            <div className="whatWeDo-section2">
                <img src={whatWeDo} />
                <div className="whatWeDo-content">
                    <div className="whatWeDo-Para">
                        <div className="whatWeDo-icon">
                            <img src={whatWeDoBrainIcon} alt="whatWeDoBrainIcon" />
                        </div>
                        <div className="whatWeDo-text">
                            <div className="heading2">AI & IoT Solutions</div>
                            <div className="paragraph1">
                            We design and implement <b>connected systems</b> that provide real-time data, predictive insights, and automation to improve business processes.
                            </div>
                        </div>
                    </div>
                    <div className="whatWeDo-Para">
                        <div className="whatWeDo-icon">
                            <img src={whatWeDoClickIcon} alt="whatWeDoBrainIcon" />
                        </div>
                        <div className="whatWeDo-text">
                            <div className="heading2">Digital Transformation</div>
                            <div className="paragraph1">
                                We help companies embrace <b>digital innovation</b> by modernizing infrastructure, optimizing workflows, and enabling remote and intelligent operations.
                            </div></div>
                    </div>
                    <div className="whatWeDo-Para">
                        <div className="whatWeDo-icon">
                            <img src={whatWeDoSettingIcon} alt="whatWeDoBrainIcon" />
                        </div>
                        <div className="whatWeDo-text">
                            <div className="heading2">Industry-Specific Solutions</div>
                            <div className="paragraph1">
                            We specialize in creating solutions for diverse industries, from enhancing <b>fleet management</b> in logistics to improving <b>worker safety</b> in construction.
                            </div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatWeDo