import React from 'react';
import TextCapsules from './TextCapsules';
import './Cards.css';

const Cards = ({ tag, title, glow, cardDetails }) => {
    return (
        <div className='container cards-head'>
            <div className="heading1 flex-container-title">
            {tag ? <TextCapsules text={tag} /> : null}
                {title}
                <span className="text-gradient">{glow}</span>
            </div>
            <div className='cards'>
            {cardDetails.map((card, index) => (
                <div key={index} className='card'>
                    <div className='number'>{"0"+(index+1)}</div>
                    <div className='heading' >{card.title}</div>
                    <div className='paragraph1'>{card.description}</div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default Cards;
