import React from 'react';
import Cards from '../../Common/Components/Cards';
import FlexContainer from '../../Common/Components/FlexContainer';
import WorkforceImg from '../../assets/workforce.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';
import workforce1 from '../../assets/workforce1.webp';
import workforce2 from '../../assets/workforce2.webp'
import workforce3 from '../../assets/workforce3.webp';
import workforce4 from '../../assets/workforce4.webp';
import workforce5 from '../../assets/workforce5.webp';
import CardsV2 from '../../Common/Components/CardsV2';
import Bannerv2 from '../../Common/Components/Bannerv2';
const Workforce = () => {



    const cardDetail = [
        {
            title: 'Real-Time Collaboration',
            description: 'Facilitates seamless communication and data sharing between teams, machines, and systems for coordinated operations.'
        },
        {
            title: 'Workforce and Asset Tracking',
            description: 'Monitors employee activity, task progress, and machine performance through IoT-enabled devices, providing actionable insights to optimize workflows.'
        },
        {
            title: 'Predictive Insights',
            description: 'Leverages AI to predict workforce needs, detect process inefficiencies, and recommend improvements for enhanced productivity.'
        },
        {
            title: 'Industrial Automation',
            description: 'Integrates with industrial systems like robotics and PLCs (Programmable Logic Controllers) to automate repetitive tasks and reduce human error.'
        },
        {
            title: 'Safety and Compliance Monitoring',
            description: 'Ensures employee safety in hazardous environments with real-time monitoring of health metrics and adherence to industrial safety protocols.'
        },
        {
            title: 'Skill Development Support',
            description: 'Provides training and knowledge-sharing through AR/VR tools and real-time feedback for upskilling the industrial workforce.'
        }

    ];



    const cardData = [
        {
            img: workforce1,
            title: 'Manufacturing',
            description: 'Connects workers with machinery for predictive maintenance, performance tracking, and improved production lines.'
        },
        {
            img: workforce2,
            title: 'Industrial Operations',
            description: 'Monitors factory workflows and enhances decision-making with real-time data from sensors and connected equipment.'
        },
        {
            img: workforce3,
            title: 'Retail',
            description: 'Improves in-store operations and employee scheduling.'
        },
        {
            img: workforce4,
            title: 'Healthcare',
            description: 'Streamlines hospital operations by connecting staff, devices, and systems.'
        },
        {
            img: workforce5,
            title: 'Logistics and Warehousing',
            description: 'Optimizes workforce allocation and machine usage for faster operations.'
        }

    ];



    return (
        <>
            <div className="container" >
                <FlexContainer
                    src={WorkforceImg}
                    tag={"Solution"}
                    glow={"CONNECTED WORKFORCE"}
                    desc={
                        <>
                            <b> Connected Workforce Solution </b>enhances <b>operational efficiency </b>and <b>productivity</b> by integrating IoT, AI, and advanced communication technologies to connect employees, tools, machines, and processes in real-time across industries, including industrial environments.
                        </>
                    }
                />
            </div>
            <Cards
                tag="Key Features"
                title="Experience the Key Features with"
                glow=" CONNECTED WORKFORCE Solution"
                cardDetails={cardDetail}
            />
            <CardsV2
                glow="Applications"
                cardData={cardData}
            />
             <Bannerv2
            desc="The Sisdea Connected Workforce Solution empowers industrial and non-industrial environments with smart connectivity, automation, and actionable insights, driving efficiency, safety, and productivity in dynamic operational settings.
"
            />
            <ContactUs />
        </>
    )
}

export default Workforce
