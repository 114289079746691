import React from 'react'
import ContactUs from '../../Common/ContactUs/ContactUs'
import FlexContainer from '../../Common/Components/FlexContainer'
import JoinUsImg from '../../assets/xAi.webp';
const Xai = () => {
    return (
        <>
            <div className="container" >
                <FlexContainer
                    src={JoinUsImg}
                    tag={"Platform"}
                    glow={"xAi"}
                    desc={
                        <>
                            <b>xAI Platform</b> leverages <b>explainable AI</b> to deliver transparent, reliable, 
                            and actionable insights. It integrates seamlessly with Sisdea's XIOT platform, offering 
                            real-time analytics, compliance with AI ethics, and an intuitive interface. Applications 
                            span <b>healthcare, finance, automotive, retail,</b> and <b>industrial IoT,</b> ensuring 
                            AI decisions are clear, trustworthy, and industry-aligned.

                        </>
                    }
                />
            </div>
            <ContactUs />
        </>
    )
}

export default Xai