import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import logistics1 from '../../assets/logistics1.webp';
import logistics2 from '../../assets/logistics2.webp';
import logistics3 from '../../assets/logistics3.webp';
import logistics from '../../assets/logistics.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';

const Logistics = () => {
  const cardDetail = [
    {
      title: 'Fleet Management',
      description: 'Real-time vehicle tracking, maintenance monitoring, and driver behavior analysis.',
    },
    {
      title: 'Warehouse Management',
      description: 'Automated inventory tracking, smart shelving, and order picking optimization.',
    },
    {
      title: 'Route Optimization',
      description: 'AI-based route planning, traffic/weather monitoring, and dynamic dispatching.',
    },
    {
      title: 'Asset Tracking',
      description: 'Real-time tracking of assets, with condition monitoring and theft prevention.',
    },
    {
      title: 'Data Analytics',
      description: 'Provides operational insights, performance tracking, and predictive analytics.',
    }
];



const cardData = [
    {
        img: logistics1,
        title: 'Increased efficiency and cost savings.',
    },
    {
        img: logistics2,
        title: 'Enhanced customer satisfaction and safety.',
    },
    {
        img: logistics3,
        title: 'Greater visibility and real-time insights.',
    },
 
];
  return (
    <>
      <div className="container" >
                 <FlexContainer
                     src={logistics}
                     tag={"Industry"}
                     glow={"Logistics"}
                     desc={
                         <>
                           Sisdea offers IoT, AI, and data analytics solutions to enhance <b>efficiency, safety,</b> and <b>cost-effectiveness</b> in logistics.
                         </>
                     }
                 />
             </div>
             <Cards
                 tag="Key Features"
                 title="Experience the Key Features with"
                 glow=" Logistics"
                 cardDetails={cardDetail}
             />
             <CardsV2
                 glow="Benefits"
                 cardData={cardData}
             />
    <Bannerv2 desc={"Sisdea’s solutions help logistics companies optimize operations and improve service delivery."}/>
    <ContactUs/>
    </>
   )
}

export default Logistics
