import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import oilgas1 from '../../assets/Oilgas1.webp';
import oilgas2 from '../../assets/Oilgas2.webp';
import oilgas3 from '../../assets/Oilgas3.webp';
import OilGasimg from '../../assets/OilGasimg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';

const Oilgas = () => {
  const cardDetail = [
    {
      title: 'Asset Management',
      description: 'Real-time tracking and condition monitoring of equipment and pipelines.',
    },
    {
      title: 'Predictive Maintenance',
      description: 'AI analytics to predict failures and optimize asset life.',
    },
    {
      title: 'Safety & Compliance',
      description: 'AI-powered surveillance, environmental monitoring, and emergency response systems.',
    },
    {
      title: 'Supply Chain Optimization',
      description: 'Inventory tracking, demand forecasting, and logistics monitoring.',
    },
    {
      title: 'Remote Operations',
      description: 'Remote monitoring, robotics, and drones for safer, more efficient operations.',
    },
  ];



const cardData = [
    {
        img: oilgas1,
        title: 'Improved efficiency and reduced downtime',
    },
    {
        img: oilgas2,
        title: 'Enhanced safety and regulatory compliance.',
    },
    {
        img: oilgas3,
        title: 'Greater sustainability and remote capabilities.',
    },
 
];
  return (
    <>
      <div className="container" >
                 <FlexContainer
                     src={OilGasimg}
                     tag={"Industry"}
                     glow={"Oil & Gas"}
                     desc={
                         <>
                            Sisdea provides IoT, AI, and data analytics solutions to enhance <b>efficiency, safety,</b> and <b>sustainability </b>in the Oil & Gas industry.
                         </>
                     }
                 />
             </div>
             <Cards
                 tag="Key Solutions"
                 title="Experience the Key Solutions with"
                 glow=" Oil & Gas"
                 cardDetails={cardDetail}
             />
             <CardsV2
                 glow="Benefits"
                 cardData={cardData}
             />
    <Bannerv2 desc={"Sisdea helps the Oil & Gas industry optimize operations, enhance safety, and reduce costs through innovative technology."}/>
    <ContactUs/>
    </>
   )
}

export default Oilgas;
