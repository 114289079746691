import React from 'react';
import Cards from '../../Common/Components/Cards';
import vehicle from '../../assets/vehicle.webp';
import FlexContainer from '../../Common/Components/FlexContainer';
import ContactUs from '../../Common/ContactUs/ContactUs';
import CardsV2 from '../../Common/Components/CardsV2';
import vehicle1 from '../../assets/Things1.webp';
import vehicle2 from '../../assets/Things2.webp';
import vehicle3 from '../../assets/Things3.webp';
import Bannerv2 from '../../Common/Components/Bannerv2';

const Vehicle = () => {



    const cardDetail = [
        {
            title: 'Vehicle Tracking',
            description: 'Real-time GPS tracking for accurate location monitoring, route optimization, and fleet management.'
        },
        {
            title: 'Driver Assistance',
            description: 'Supports ADAS features such as collision warning, lane-keeping assistance, and real-time alerts to improve driving behavior and safety.'
        },
        {
            title: 'Safety Enhancements',
            description: 'Advanced features like emergency alerts, automatic crash detection, and remote vehicle diagnostics to ensure safety.'
        },
        {
            title: 'Predictive Maintenance',
            description: 'Analyzes driving patterns to predict potential risks and maintenance needs, helping avoid accidents and reduce downtime.'
        }
    ];



    const cardData = [
        {
            img: vehicle1,
            title: 'Fleet Management',
            description: 'Tracking and optimizing routes, monitoring driver behavior, and ensuring safety.'
        },
        {
            img: vehicle2,
            title: 'Driver Safety',
            description: 'Enhancing safety through real-time alerts and predictive maintenance.'
        },
        {
            img: vehicle3,
            title: 'Smart Cities',
            description: 'Contributing to safer roads and more efficient traffic flow.'
        },
     
    ];



    return (
        <>
            <div className="container" >
                <FlexContainer
                    src={vehicle}
                    tag={"Solution"}
                    glow={"Connected Vehicle"}
                    desc={
                        <>
                            <b>Connected Vehicle Solution</b> focuses on <b>tracking, driver assistance, </b>and <b>safety</b> by integrating <b>IoT</b> and <b>AI</b> technologies for real-time vehicle monitoring, predictive analytics, and enhanced safety features.
                        </>
                    }
                />
            </div>
            <Cards
                tag="Key Features"
                title="Experience the Key Features with"
                glow=" CONNECTED VEHICLE Solution"
                cardDetails={cardDetail}
            />
            <CardsV2
                glow="Applications"
                cardData={cardData}
            />
             <Bannerv2 
             desc="The Sisdea Connected Vehicle Solution ensures safer, more efficient driving experiences through real-time tracking, advanced driver assistance systems, and predictive safety features." />
            <ContactUs />
        </>
    )
}

export default Vehicle
