import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/f-logo.svg";


const Footer = () => {
  return (
    <>
    <div className="footer container-mt">
    <div className="footer-main">
      <img src={logo} alt="logo" className="logo" />
      <div className="footer-sections">
        <div className="footer-column">
          <div className="footer-redirect-header">Quick Link</div>
          <div className="hr"></div>
          <div className="footer-redirect">
          <div className="paragraph2">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link>
          </div>
          <div className="paragraph2">
            <Link to="/aboutus" onClick={() => window.scrollTo(0, 0)}>About Us</Link>
          </div>
          <div className="paragraph2">
            <Link to="/careers" onClick={() => window.scrollTo(0, 0)}>Careers</Link>
          </div>
          <div className="paragraph2">
            <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact Us</Link>
          </div>
          </div>
        </div>
  
        <div className="footer-column">
          <div className="footer-redirect-header">Platform</div>
          <div className="hr"></div>
          <div className="footer-redirect">
          <div className="paragraph2">
            <Link to="/xiot" onClick={() => window.scrollTo(0, 0)}>xIOT</Link>
          </div>
          <div className="paragraph2">
            <Link to="/xai" onClick={() => window.scrollTo(0, 0)}>xAI</Link>
          </div>
          <div className="paragraph2">
            <Link to="/xtwin" onClick={() => window.scrollTo(0, 0)}>xTwin</Link>
          </div>
          </div>
        </div>
  
        <div className="footer-column">
          <div className="footer-redirect-header">Solutions</div>
          <div className="hr"></div>
          <div className="footer-redirect">
          <div className="paragraph2">
            <Link to="/connected-vehicle" onClick={() => window.scrollTo(0, 0)}>Connected Vehicle</Link>
          </div>
          <div className="paragraph2">
            <Link to="/connected-asset" onClick={() => window.scrollTo(0, 0)}>Connected Asset</Link>
          </div>
          <div className="paragraph2">
            <Link to="/connected-workforce" onClick={() => window.scrollTo(0, 0)}>Connected Workforce</Link>
          </div>
          <div className="paragraph2">
            <Link to="/connected-site" onClick={() => window.scrollTo(0, 0)}>Connected Site</Link>
          </div>
          <div className="paragraph2">
            <Link to="/connected-things" onClick={() => window.scrollTo(0, 0)}>Connected Things</Link>
          </div>
          </div>
        </div>
  
        <div className="footer-column">
          <div className="footer-redirect-header">Industry</div>
          <div className="hr"></div>
          <div className="footer-redirect">
          <div className="paragraph2">
            <Link to="/automotive" onClick={() => window.scrollTo(0, 0)}>Automotive</Link>
          </div>
          <div className="paragraph2">
            <Link to="/utility" onClick={() => window.scrollTo(0, 0)}>Utility</Link>
          </div>
          <div className="paragraph2">
            <Link to="/manufacturing" onClick={() => window.scrollTo(0, 0)}>Manufacturing</Link>
          </div>
          <div className="paragraph2">
            <Link to="/logistics" onClick={() => window.scrollTo(0, 0)}>Logistics</Link>
          </div>
          <div className="paragraph2">
            <Link to="/oil-and-gas" onClick={() => window.scrollTo(0, 0)}>Oil & Gas</Link>
          </div>
          <div className="paragraph2">
            <Link to="/construction" onClick={() => window.scrollTo(0, 0)}>Construction</Link>
          </div>
          <div className="paragraph2">
            <Link to="/consumer" onClick={() => window.scrollTo(0, 0)}>Consumer</Link>
          </div>
        </div>
        </div>
      </div>
    </div>
  

  </div>
  
  <div className="footer-section2">
  <div className="footer-social-links">
    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
<path d="M20 10.3038C20 4.74719 15.5229 0.242676 10 0.242676C4.47715 0.242676 0 4.74719 0 10.3038C0 15.3255 3.65684 19.4879 8.4375 20.2427V13.2121H5.89844V10.3038H8.4375V8.0872C8.4375 5.56564 9.9305 4.1728 12.2146 4.1728C13.3088 4.1728 14.4531 4.36931 14.4531 4.36931V6.84529H13.1922C11.95 6.84529 11.5625 7.6209 11.5625 8.41658V10.3038H14.3359L13.8926 13.2121H11.5625V20.2427C16.3432 19.4879 20 15.3257 20 10.3038Z" fill="white"/>
</svg>
    </a>
    <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-link">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13 0.242676H5C2.23858 0.242676 0 2.48126 0 5.24268V13.2427C0 16.0041 2.23858 18.2427 5 18.2427H13C15.7614 18.2427 18 16.0041 18 13.2427V5.24268C18 2.48126 15.7614 0.242676 13 0.242676ZM16.25 13.2427C16.2445 15.0353 14.7926 16.4872 13 16.4927H5C3.20735 16.4872 1.75549 15.0353 1.75 13.2427V5.24268C1.75549 3.45003 3.20735 1.99817 5 1.99268H13C14.7926 1.99817 16.2445 3.45003 16.25 5.24268V13.2427ZM13.75 5.49268C14.3023 5.49268 14.75 5.04496 14.75 4.49268C14.75 3.9404 14.3023 3.49268 13.75 3.49268C13.1977 3.49268 12.75 3.9404 12.75 4.49268C12.75 5.04496 13.1977 5.49268 13.75 5.49268ZM9 4.74268C6.51472 4.74268 4.5 6.7574 4.5 9.24268C4.5 11.728 6.51472 13.7427 9 13.7427C11.4853 13.7427 13.5 11.728 13.5 9.24268C13.5027 8.04838 13.0294 6.90225 12.1849 6.05776C11.3404 5.21327 10.1943 4.74002 9 4.74268ZM6.25 9.24268C6.25 10.7615 7.4812 11.9927 9 11.9927C10.5188 11.9927 11.75 10.7615 11.75 9.24268C11.75 7.72388 10.5188 6.49268 9 6.49268C7.4812 6.49268 6.25 7.72388 6.25 9.24268Z" fill="white"/>
</svg>
    </a>
    <a href="https://www.x.com" target="_blank" rel="noopener noreferrer" className="social-link">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17" viewBox="0 0 18 17" fill="none">
<path d="M14.1761 0.242676H16.9362L10.9061 7.02008L18 16.2427H12.4456L8.0951 10.6493L3.11723 16.2427H0.35544L6.80517 8.99348L0 0.242676H5.69545L9.6279 5.3553L14.1761 0.242676ZM13.2073 14.6181H14.7368L4.86441 1.78196H3.2232L13.2073 14.6181Z" fill="white"/>
</svg>
    </a>
    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-link">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0.242676C0.67157 0.242676 0 0.914246 0 1.74268V16.7427C0 17.5711 0.67157 18.2427 1.5 18.2427H16.5C17.3284 18.2427 18 17.5711 18 16.7427V1.74268C18 0.914246 17.3284 0.242676 16.5 0.242676H1.5ZM5.52076 4.2454C5.52639 5.20165 4.81061 5.79087 3.96123 5.78665C3.16107 5.78243 2.46357 5.1454 2.46779 4.24681C2.47201 3.40165 3.13998 2.72243 4.00764 2.74212C4.88795 2.76181 5.52639 3.40728 5.52076 4.2454ZM9.2797 7.00444H6.75971H6.7583V15.5643H9.4217V15.3646C9.4217 14.9847 9.4214 14.6047 9.4211 14.2246C9.4203 13.2108 9.4194 12.1959 9.4246 11.1824C9.426 10.9363 9.4372 10.6804 9.5005 10.4455C9.7381 9.56798 10.5271 9.00128 11.4074 9.14058C11.9727 9.22908 12.3467 9.55678 12.5042 10.0898C12.6013 10.423 12.6449 10.7816 12.6491 11.129C12.6605 12.1766 12.6589 13.2242 12.6573 14.2719C12.6567 14.6417 12.6561 15.0117 12.6561 15.3815V15.5629H15.328V15.3576C15.328 14.9056 15.3278 14.4537 15.3275 14.0018C15.327 12.8723 15.3264 11.7428 15.3294 10.6129C15.3308 10.1024 15.276 9.59898 15.1508 9.10538C14.9638 8.37128 14.5771 7.76378 13.9485 7.32508C13.5027 7.01287 13.0133 6.81178 12.4663 6.78928C12.404 6.78669 12.3412 6.7833 12.2781 6.77989C11.9984 6.76477 11.7141 6.74941 11.4467 6.80334C10.6817 6.95662 10.0096 7.30678 9.5019 7.92408C9.4429 7.99488 9.3852 8.06678 9.2991 8.17408L9.2797 8.19838V7.00444ZM2.68164 15.5671H5.33242V7.01001H2.68164V15.5671Z" fill="white"/>
</svg>
    </a>
    <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer" className="social-link">
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
<path d="M20.5933 2.20301C20.4794 1.78041 20.2568 1.39501 19.9477 1.08518C19.6386 0.775338 19.2537 0.551868 18.8313 0.437007C17.2653 0.00700739 11.0003 7.59651e-06 11.0003 7.59651e-06C11.0003 7.59651e-06 4.73633 -0.00699261 3.16933 0.404007C2.74725 0.524154 2.36315 0.750785 2.0539 1.06214C1.74464 1.3735 1.52062 1.75913 1.40333 2.18201C0.99033 3.74801 0.98633 6.99601 0.98633 6.99601C0.98633 6.99601 0.98233 10.26 1.39233 11.81C1.62233 12.667 2.29733 13.344 3.15533 13.575C4.73733 14.005 10.9853 14.012 10.9853 14.012C10.9853 14.012 17.2503 14.019 18.8163 13.609C19.2388 13.4943 19.6241 13.2714 19.934 12.9622C20.2439 12.653 20.4677 12.2682 20.5833 11.846C20.9973 10.281 21.0003 7.03401 21.0003 7.03401C21.0003 7.03401 21.0203 3.76901 20.5933 2.20301ZM8.99633 10.005L9.00133 4.00501L14.2083 7.01001L8.99633 10.005Z" fill="white"/>
</svg>
    </a>
  </div>
  <div className="footer-info">
   SISDEA 2024. All rights reserved.
    <div className="footer-legal-links">
      <a href="/privacy-policy" className="legal-link">Privacy Policy</a>
      <a href="/terms-of-service" className="legal-link">Terms of Service</a>
      <a href="/cookie-settings" className="legal-link">Cookies Settings</a>
    </div>
  </div>
</div>
  </>
   
  );
};

export default Footer;


// <div className="footer-end">
       
// <div className="footer-static-page-links">
//   <div className="paragraph2">SISDEA 2024 . All right reserved.</div>
//   <a
//     href="/privacy-policy"
//     className="paragraph2"
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     Privacy Policy
//   </a>
//   <a
//     href="/terms"
//     className="paragraph2"
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     Terms of Service
//   </a>
//   {/* <div className="paragraph2">Cookies Settings</div> */}
// </div>
// </div>


     {/* <div className="social-links">
          <div>
            <img src={fbIcon} alt="fbIcon" />
          </div>
          <div>
            <img src={instaIcon} alt="instaIcon" />
          </div>
          <div>
            <img src={twitterIcon} alt="twitterIcon" />
          </div>
          <div>
            <img src={linkedinIcon} alt="linkedinIcon" />
          </div>
          <div>
            <img src={youtubeIcon} alt="youtubeIcon" />
          </div>
        // </div> */}
      // </div>
  
    // </div>