import React from 'react';
import Cards from '../../Common/Components/Cards';
import AssetImg from '../../assets/Asset.webp';
import FlexContainer from '../../Common/Components/FlexContainer';
import ContactUs from '../../Common/ContactUs/ContactUs';
import CardsV2 from '../../Common/Components/CardsV2';
import Site1 from '../../assets/Site1.webp';
import Site2 from '../../assets/Site2.webp'
import Site3 from '../../assets/Site3.webp';
import Site4 from '../../assets/Site4.webp';
import Bannerv2 from '../../Common/Components/Bannerv2';
import TextCapsules from '../../Common/Components/TextCapsules';

const Asset = () => {
    const cardDetail = [
        {
            title: 'Real-Time Asset Monitoring',
            description: 'Continuously tracks asset health, performance, and usage with real-time data collection and analytics.'
        },
        {
            title: 'Predictive Maintenance',
            description: 'Uses AI to predict asset failures before they occur, minimizing downtime and reducing repair costs.'
        },
        {
            title: 'Asset Tracking and Visibility',
            description: 'Provides real-time location tracking for mobile and stationary assets, improving asset visibility and reducing loss or theft.'
        },
        {
            title: 'Data-Driven Insights',
            description: 'Delivers actionable insights on asset performance, utilization, and lifecycle to optimize operations.'
        },
        {
            title: 'Scalability',
            description: 'Adaptable to a wide range of industries, including manufacturing, logistics, healthcare, and more.'
        }

    ];
    const cardData = [
        {
            img: Site1,
            title: 'Manufacturing',
            description: 'Monitoring machinery, equipment, and production lines for optimal performance.'
        },
        {
            img: Site2,
            title: 'Driver Safety',
            description: 'Tracking transportation fleets, containers, and shipments.'
        },
        {
            img: Site3,
            title: 'Healthcare',
            description: 'Tracking medical equipment, ensuring availability and compliance.'
        },
        {
            img: Site4,
            title: 'Construction',
            description: 'Managing tools, Sites, and heavy equipment in real-time.'
        },

    ];
    return (
        <>

            <div className="container" >
                <FlexContainer
                    src={AssetImg}
                    tag={"Solution"}
                    glow={"Connected Asset"}
                    desc={
                        <>
                            <b>Connected Asset Solution</b> leverages <b>IoT</b> and <b>AI</b> technologies to enable real-time monitoring, tracking, and management of assets across industries. It enhances asset performance, ensures uptime, and optimizes maintenance processes.
                        </>
                    }
                />
            </div>
            <Cards
                tag="Key Features"
                title="Experience the Key Features with"
                glow=" Connected Asset Solution"
                cardDetails={cardDetail}
            />
            <CardsV2
                glow="Applications"
                cardData={cardData}
            />
            <Bannerv2
                desc="The Sisdea Connected Asset Solution improves operational efficiency, reduces costs, and enhances asset lifecycle management through real-time monitoring, predictive analytics, and optimized maintenance."
            />
         

            <ContactUs />
        </>
    )
}

export default Asset
