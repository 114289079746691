import React from 'react'
import Bannerv2 from './../../Common/Components/Bannerv2';
import FlexContainer from '../../Common/Components/FlexContainer';
import Cards from '../../Common/Components/Cards';
import CardsV2 from '../../Common/Components/CardsV2';
import automotive1 from '../../assets/automotive1.webp';
import automotive2 from '../../assets/automotive2.webp';
import automotive3 from '../../assets/automotive3.webp';
import automotiveimg from '../../assets/Automotiveimg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';
const Automotive = () => {


  const cardDetail = [
    {
      title: 'Fleet Management',
      description: (
        <ul style={{ padding: "20px" }}>
          <li><b>Real-Time Tracking:</b> GPS-enabled visibility for vehicles and trailers.</li>
          <li><b>Driver Monitoring:</b> Behavior analysis and fatigue detection.</li>
          <li><b>Predictive Maintenance:</b> Reduces downtime with proactive alerts.</li>
          <li><b>Route Optimization:</b> AI-driven cost-efficient routing.</li>
        </ul>
      ),
    },
    {
      title: 'Manufacturing & Assembly Lines',
      description: (
        <ul style={{ padding: "20px" }}>
          <li><b>IoT Integration:</b> Monitors machines and assembly in real-time.</li>
          <li><b>Defect Detection:</b> AI systems identify flaws during production.</li>
          <li><b>Digital Twins:</b> Simulates and optimizes production processes.</li>
          <li><b>Inventory Automation:</b> Streamlines supply chain operations.</li>
        </ul>
      ),
    },
    {
      title: 'Driver Assistance (ADAS)',
      description: (
        <ul style={{ padding: "20px" }}>
          <li><b>Lane Assist, Collision Avoidance, Blind Spot Monitoring:</b> Ensures safety while driving.</li>
          <li><b>Fatigue Detection:</b> AI-enabled safety for drivers.</li>
          <li><b>Adaptive Cruise Control:</b> Ensures safe distances on the road.</li>
        </ul>
      ),
    },
    {
      title: 'Vehicle/Trailer Tracking',
      description: (
        <ul style={{ padding: "20px" }}>
           <li><b>Geofencing & Load Monitoring:</b> Ensures cargo safety and optimal conditions.</li>
           <li><b>Remote Locking:</b> Enhances security against theft.</li>
        </ul>
      ),
    }

  ];
  const cardData = [
    {
      img: automotive1,
      title: 'Increased Productivity',
      description: 'Tracking and optimizing routes, monitoring driver behavior, and ensuring safety.'
    },
    {
      img: automotive2,
      title: 'Cost Savings',
      description: 'Enhancing safety through real-time alerts and predictive maintenance.'
    },
    {
      img: automotive3,
      title: 'Centralized Platform',
      description: 'Contributing to safer roads and more efficient traffic flow.'
    },

  ];
  return (
    <>
      <div className="container" >
        <FlexContainer
          src={automotiveimg}
          tag={"Industry"}
          glow={"Automotive"}
          desc={
            <>
              Sisdea provides advanced solutions for <b>fleet management, manufacturing assembly lines, driver assistance,</b> and <b>trailer tracking,</b> designed to enhance <b>efficiency</b> and <b>safety </b>.
            </>
          }
        />
      </div>
      <Cards
        tag="Key Solutions"
        title="Experience the Key Solutions with"
        glow=" Automotive"
        cardDetails={cardDetail}
      />
      <CardsV2
        glow="Benefits"
        cardData={cardData}
      />
      <Bannerv2 desc={"Sisdea’s innovations drive efficiency and safety across the automotive ecosystem."} />
      <ContactUs />
    </>
  )
}

export default Automotive
