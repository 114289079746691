import React from 'react'
import Cards from '../../Common/Components/Cards'
import FlexContainer from '../../Common/Components/FlexContainer';
import SiteImg from '../../assets/SiteImg.webp';
import ContactUs from '../../Common/ContactUs/ContactUs';
import Site1 from '../../assets/Site1.webp';
import Site2 from '../../assets/Site2.webp'
import Site3 from '../../assets/Site3.webp';
import Site4 from '../../assets/Site4.webp';
import CardsV2 from '../../Common/Components/CardsV2';
import Bannerv2 from '../../Common/Components/Bannerv2';
const Site = () => {



    const cardDetail = [
        {
            title: 'AI Video-Based Safety',
            description: 'Utilizes AI-powered cameras to monitor onsite activities, detect hazards, and ensure compliance with safety protocols in real time.'
        },
        {
            title: 'Site Visibility and Analytics',
            description: 'Delivers a comprehensive view of site operations with actionable insights to improve safety, security, and productivity.'
        },
        {
            title: 'Incident Detection and Alerts',
            description: 'Automatically identifies unsafe behaviors, unauthorized access, or equipment malfunctions and triggers instant alerts for immediate response.'
        },
        {
            title: 'Security Integration',
            description: 'Enhances site security with features like facial recognition, perimeter monitoring, and intrusion detection.'
        },
        {
            title: 'Operational Efficiency',
            description: 'Tracks workflow progress, equipment utilization, and resource allocation to optimize site performance.'
        }

    ];




    const cardData = [
        {
            img: Site1,
            title: 'Construction',
            description: 'Ensures worker safety and monitors progress while minimizing risks and inefficiencies.'
        },
        {
            img: Site2,
            title: 'Industrial Facilities',
            description: 'Provides real-time monitoring of machinery and personnel to maintain compliance and security.'
        },
        {
            img: Site3,
            title: 'Smart Factories',
            description: 'Enhances automation and safety in production facilities through advanced visibility.'
        },
        {
            img: Site4,
            title: 'Logistics and Warehousing',
            description: 'Tracks vehicle movements, monitors loading/unloading zones, and ensures site safety.'
        }

    ];



    return (

        <>
            <div className="container" >
                <FlexContainer
                    src={SiteImg}
                    tag={"Solution"}
                    glow={"CONNECTED SITE"}
                    desc={
                        <>
                            <b>Connected Site Solution</b> leverages <b>AI-driven video analytics </b>to enhance <b>safety, security, </b>and <b>efficiency </b>across worksites, from the field to facilities. This solution provides real-time <b>site visibility</b> to support organizations in achieving their safety and operational goals.
                        </>
                    }
                />
            </div>F
            <Cards
                tag="Key Features"
                title="Experience the Key Features with"
                glow=" Connected Site Solution"
                cardDetails={cardDetail}
            />
            <CardsV2
                glow="Applications"
                cardData={cardData}
            />
            <Bannerv2
            desc="The Sisdea Connected Site Solution offers a robust platform to improve workplace safety, streamline operations, and secure your facilities with cutting-edge video-based AI technology."
            />
            <ContactUs />
        </>
    )
}

export default Site
