import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import TextCapsules from "../Components/TextCapsules";
import logo from "../../assets/logo.svg";
import arrow from "../../assets/arrow-down-line.svg";

const NAV_ITEMS = [
  {
    key: "platform",
    label: "Platform",
    dropdown: [
      { label: "xIOT", path: "/xiot" },
      { label: "xAI", path: "/xai" },
      { label: "xTwin", path: "/xtwin" },
    ],
  },
  {
    key: "solutions",
    label: "Solutions",
    dropdown: [
      { label: "Connected Vehicle", path: "/connected-vehicle" },
      { label: "Connected Asset", path: "/connected-asset" },
      { label: "Connected Workforce", path: "/connected-workforce" },
      { label: "Connected Site", path: "/connected-site" },
      { label: "Connected Things", path: "/connected-things" },
    ],
  },
  {
    key: "industry",
    label: "Industry",
    dropdown: [
      { label: "Automotive", path: "/automotive" },
      { label: "Utility", path: "/Utility" },
      { label: "Manufacturing", path: "/manufacturing" },
      { label: "Logistics", path: "/logistics" },
      { label: "Oil & Gas", path: "/oil-and-gas" },
      { label: "Construction", path: "/construction" },
      { label: "Consumer", path: "/consumer" },
    ],
  },
  { key: "aboutus", label: "About", path: "/aboutus" },
];

const Navbar = () => {
  const navigate = useNavigate();
  const [lastClicked, setLastClicked] = useState("platform");
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".nav-item, .dropdown-container")) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const toggleDropdown = (key) => {
    setActiveDropdown((prev) => (prev === key ? null : key));
    setLastClicked(key);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setActiveDropdown(null);
    setMobileMenuOpen(false);
  };

  const renderDropdown = (items) => (
    <div className="dropdown-container">
      {items.map(({ label, path }) => (
        <div
          key={label}
          className="dropdown-item"
          onClick={() => handleNavigation(path)}
        >
          {label}
        </div>
      ))}
    </div>
  );


  return (
    <div className="header">
      <div className="navbar container-mw container-pd">
        <Link
          to="/"
          onClick={() => {
            setLastClicked("platform");
            setActiveDropdown(null);
          }}
        >
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <div className="navs">
          <div className={`nav-items ${mobileMenuOpen ? "nav-items-mob" : ""}`}>
            {NAV_ITEMS.map(({ key, label, dropdown, path }) => (
              <div
                key={key}
                className={`nav-item header-text ${lastClicked === key ? "active" : ""
                  }`}
              >
                <div
                  onClick={() => {
                    if (dropdown) {
                      toggleDropdown(key);
                    } else {
                      handleNavigation(path);
                      setLastClicked(key); // Ensure non-dropdown items also update `lastClicked`
                    }
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    padding: "10px",
                  }}
                >
                  <span>{label}</span>
                  {dropdown && (
                    <img
                      src={arrow}
                      alt="dropdown-arrow"
                      style={{
                        height: "20px",
                        transform:
                          activeDropdown === key ? "rotate(180deg)" : "unset",
                        transition: "transform 0.25s ease-in-out",
                      }}
                    />
                  )}
                </div>
                {activeDropdown === key && dropdown && renderDropdown(dropdown)}
              </div>
            ))}

            {/* Move TextCapsules inside nav-items for mobile view */}
            <TextCapsules
              text={"Contact us"}
              textColor={"white"}
              bgColor={"#2D2D2D"}
              isClickable={true}
              href="/contact"
              className="mobile-contact-us-btn"
            />
          </div>
          <div
            className="hamburger-menu"
            onClick={() => setMobileMenuOpen((prev) => !prev)}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;