import React from 'react'
import ContactUs from '../../Common/ContactUs/ContactUs';
import xiot from '../../assets/xIOT.webp';
import FlexContainer from '../../Common/Components/FlexContainer';

const Xiot = () => {
  return (
    <>
      <div className="container" >
        <FlexContainer
          src={xiot}
          tag={"Platform"}
          glow={"xIOT"}
          desc={
            <>
              <b>xIOT platform</b> is an advanced <b>AIoT stack</b> combining <b>AI</b> and <b>IoT</b> to create intelligent, 
              connected systems. It offers features like smart device integration, real-time AI analytics, 
              scalability, robust security, and energy efficiency. Key applications include <b>smart homes, 
              industrial IoT, healthcare, automotive, agriculture,</b> and <b>retail,</b> enabling predictive insights, 
              automation, and sustainability. Sisdea's xIOT aims to transform industries by enhancing efficiency, 
              safety, and innovation. 
            </>
          }
        />
      </div>
      {console.log("changeincode")}
      <ContactUs />
    </>
  )
}

export default Xiot