import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import Footer from "./Common/Footer/Footer";
import Navbar from "./Common/Navbar/Navbar";
import AboutUs from "./Pages/AboutUs/AboutUs";
import Home from "./Pages/Home/Home";
import AiTutor from "./Pages/OurProduct/AiTutor/AiTutor";
import FaultDetection from "./Pages/OurProduct/FaultDetection/FaultDetection";
import Melodi from "./Pages/OurProduct/Melodi/Melodi";
import TandC from "./Pages/StaticContent/TandC";
import PrivacyPolicy from "./Pages/StaticContent/PrivacyPolicy";
import Spanfi from "./Pages/OurProduct/Spanfi/Spanfi";
import Contact from "./Pages/Contact/Contact";
import Careers from "./Pages/Careers/Careers";
import Xai from "./Pages/Platform/Xai";
import Xiot from "./Pages/Platform/Xiot";
import Xtwin from "./Pages/Platform/Xtwin";
import Vehicle from "./Pages/Solution/Vehicle";
import Asset from "./Pages/Solution/Asset";
import Site from "./Pages/Solution/Site";
import Workforce from "./Pages/Solution/Workforce";
import Things from "./Pages/Solution/Things";
import Automotive from './Pages/Industry/Automotive';
import Construction from './Pages/Industry/Construction';
import Consumer from './Pages/Industry/Consumer';
import Logistics from './Pages/Industry/Logistics';
import Manufacturing from './Pages/Industry/Manufacturing';
import Oilgas from './Pages/Industry/Oilgas';
import Utitlity from './Pages/Industry/Utitlity';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/melodi" element={<Melodi />} />
        <Route path="/doctalk" element={<AiTutor />} />
        <Route path="/fault-detection" element={<FaultDetection />} />
        <Route path="/spanfi" element={<Spanfi />} />
        <Route path="/terms" element={<TandC />} />
        <Route path="/xai" element={<Xai />} />
        <Route path="/xiot" element={<Xiot />} />
        <Route path="/xtwin" element={<Xtwin />} />
        <Route path="/connected-vehicle" element={<Vehicle />} />
        <Route path="/connected-asset" element={<Asset />} />
        <Route path="/connected-site" element={<Site />} />
        <Route path="/connected-workforce" element={<Workforce />} />
        <Route path="/connected-things" element={<Things />} />
        <Route path="/automotive" element={<Automotive />} />
        <Route path="/utility" element={<Utitlity />} />
        <Route path="/manufacturing" element={<Manufacturing />} />
        <Route path="/logistics" element={<Logistics />} />
        <Route path="/oil-and-gas" element={<Oilgas />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/consumer" element={<Consumer />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
