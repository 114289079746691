import React from 'react'
import './CardV2.css'
const CardsV2 = ({glow,cardData}) => {
  return (
  <>
     <div className='container cards-headv2'>
            <div className="heading1 flex-container-title">
                {glow?(<span className="text-gradient">{glow}</span>):("")}
            </div>
            <div className='cardsv2'>
            {cardData.map((card, index) => (
                <div key={index} className='cardv2'>
                    {card.img.includes('<svg>')?(<div className='imgv2'>{card.img}</div>):(<div className='imgv2'><img  src={card.img} alt="img"/></div>)}
                    <div className='headingv2' >{card.title}</div>
                    <div className='paragraph1v2'>{card.description}</div>
                </div>
            ))}
            </div>
        </div>
  </>
  )
}

export default CardsV2
