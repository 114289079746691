import React from 'react'
import WhatWeOffer from './Sections/WhatWeOffer'
import JoinUs from './Sections/JoinUs'

const Careers = () => {
  return (
    <>
    <JoinUs/>
    <WhatWeOffer/>
    </>
  )
}

export default Careers